export const homepageapi = async (data, youtubelinks, faqstate, dispatch) => {
  const { firstText, secondText, sideimage, key_image, back_image, key_text } =
    data;

  const checkobject = () => {
    let returnobj = {};
    if (youtubelinks) {
      returnobj.youtubeLink = youtubelinks;
    }
    if (firstText) {
      returnobj.firstText = firstText;
    }
    if (secondText) {
      returnobj.secondText = secondText;
    }
    if (sideimage) {
      returnobj.sideimage = sideimage;
    }
    if (key_image) {
      returnobj.key_image = key_image;
    }
    if (key_image) {
      returnobj.back_image = back_image;
    }
    if (key_text) {
      returnobj.key_text = key_text;
    }
    returnobj.faq = faqstate;
    return returnobj;
  };

  const post = await fetch("https://api.rendernow.co/postHomeGoals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkobject()),
  });

  if (post.status === 201) {
    dispatch({ type: "home_message_true" });
    setTimeout(() => {
      dispatch({ type: "home_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "home_message_false" });
  }
};

export const dashboardpageapi = async (
  data,
  youtubelinks = "d2cOXy4Qekg",
  dispatch
) => {
  const { welcome, welcome_text } = data;

  const checkobject = () => {
    let returnobj = {};
    if (youtubelinks) {
      returnobj.youtubeLink = youtubelinks;
    }
    if (welcome) {
      returnobj.welcome = welcome;
    }
    if (welcome_text) {
      returnobj.welcomeText = welcome_text;
    }
    return returnobj;
  };

  const post = await fetch("https://api.rendernow.co/postDashboardGoals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkobject()),
  });
  if (post.status === 201) {
    dispatch({ type: "dash_message_true" });
    setTimeout(() => {
      dispatch({ type: "dash_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "dash_message_false" });
  }
};

export const renderpageapi = async (data, dispatch) => {
  const {
    links,
    sdklink,
    purewebProjectId,
    purewebModelId,
    purewebProjectIdDev,
    purewebModelIdDev,
    purewebProjectIdGlobal,
    purewebModelIdGlobal,
    publicDownloadUrl,
  } = data;
  const checkobject = () => {
    let returnobj = {};
    if (links) {
      returnobj.link = links;
    }
    if (sdklink) {
      returnobj.mainlink = sdklink;
    }
    if (purewebProjectId) {
      returnobj.purewebProjectId = purewebProjectId;
    }
    if (purewebModelId) {
      returnobj.purewebModelId = purewebModelId;
    }
    if (purewebProjectIdDev) {
      returnobj.purewebProjectIdDev = purewebProjectIdDev;
    }
    if (purewebModelIdDev) {
      returnobj.purewebModelIdDev = purewebModelIdDev;
    }
    if (purewebProjectIdGlobal) {
      returnobj.purewebProjectIdGlobal = purewebProjectIdGlobal;
    }
    if (purewebModelIdGlobal) {
      returnobj.purewebModelIdGlobal = purewebModelIdGlobal;
    }

    if (publicDownloadUrl) {
      returnobj.publicDownloadUrl = publicDownloadUrl;
    }

    return returnobj;
  };

  const post = await fetch("https://api.rendernow.co/postRenderGoals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkobject()),
  });
  if (post.status === 201) {
    dispatch({ type: "render_message_true" });
    setTimeout(() => {
      dispatch({ type: "render_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "render_message_false" });
  }
};

export const supportpageapi = async (data, faqstate, dispatch) => {
  const { main, below, link } = data;

  const checkobject = () => {
    let returnobj = {};
    if (main) {
      returnobj.main = main;
    }
    if (below) {
      returnobj.below = below;
    }
    if (link) {
      returnobj.link = link;
    }
    returnobj.faq = faqstate;
    return returnobj;
  };

  const post = await fetch(
    "https://api.rendernow.co/postSupportGoals",
    // "https://testing-purpose-01.herokuapp.com/test",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkobject()),
    }
  );
  if (post.status === 201) {
    dispatch({ type: "support_message_true" });
    setTimeout(() => {
      dispatch({ type: "support_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "support_message_false" });
  }
};

export const resourcespageapi = async (data, youtubelinks, dispatch) => {
  const text = data.text;
  const image = data.image;

  const checkobject = () => {
    let returnobj = {};
    if (youtubelinks) {
      returnobj.youtubeLink = youtubelinks;
    }
    if (text) {
      returnobj.text = text;
    }
    if (image) {
      returnobj.image = image;
    }
    return returnobj;
  };
  const post = await fetch("https://api.rendernow.co/postResourceGoals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkobject()),
  });
  if (post.status === 201) {
    dispatch({ type: "res_message_true" });
    setTimeout(() => {
      dispatch({ type: "res_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "res_message_false" });
  }
};

export const downloadspageapi = async (data, youtubelinks, notes, dispatch) => {
  const checkobject = () => {
    let returnobj = {};
    if (youtubelinks) {
      returnobj.youtubeLink = youtubelinks;
    }

    if (notes) {
      returnobj.notes = notes;
    }

    return returnobj;
  };
  const post = await fetch("https://api.rendernow.co/postDownloadGoals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkobject()),
  });
  if (post.status === 201) {
    dispatch({ type: "download_message_true" });
    setTimeout(() => {
      dispatch({ type: "download_message_emp" });
    }, 5000);
  } else {
    dispatch({ type: "download_message_false" });
  }
};

export const getalldata = async (dispatch) => {
  const data = await fetch("https://api.rendernow.co/getHomeGoals");
  const data2 = await fetch("https://api.rendernow.co/getDashboardGoals");
  const data3 = await fetch("https://api.rendernow.co/getResourceGoals");
  const data4 = await fetch("https://api.rendernow.co/getRenderGoals");
  console.log("GETTING ALL DATA");
  console.log(data4);
  const data5 = await fetch("https://api.rendernow.co/getSupportGoals");
  const data6 = await fetch("https://api.rendernow.co/getDownloadGoals");
  const maillist = await fetch("https://api.rendernow.co/getVisitorEmailList", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: "Admin@rendernow.co",
      password: "Admin_12345",
    }),
  });

  const lists = await maillist.json();
  if (lists) {
    console.log("listysss", lists);
    dispatch({ type: "fill_mail", payload: { mailinglist: lists } });
  }
  const data_result = await data.json();
  const data_result2 = await data2.json();
  const data_result3 = await data3.json();
  const data_result4 = await data4.json();
  const data_result5 = await data5.json();
  const data_result6 = await data6.json();

  if (data_result) {
    dispatch({
      type: "hompage_data",
      payload: {
        firstText: data_result.firstText,
        secondText: data_result.secondText,
        bannerImage: data_result.sideimage,
        homepageYoutube: data_result.youtubeLink,
        keyboardSectionText: data_result.keyText,
        keyboardImage: data_result.keyImage,
        faqQuestions: data_result.faq,
        backgroundImage: data_result.backgroundImage,
      },
    });
  }
  if (data_result2) {
    dispatch({
      type: "dashboard",
      payload: {
        welcome: data_result2.welcome,
        welcome_text: data_result2.welcomeText,
        explain_video: data_result2.youtubeLink,
      },
    });
  }
  if (data_result5) {
    dispatch({
      type: "support",
      payload: {
        head_text: data_result5.main,
        below_text: data_result5.below,
        iframe_link: data_result5.link,
        faq: data_result5.faq,
      },
    });
  }
  if (data_result3) {
    dispatch({
      type: "resorces",
      payload: {
        youtube: data_result3.youtubeLink,
        key_text: data_result3.text,
        key_image: data_result3.image,
      },
    });
  }
  if (data_result4) {
    dispatch({
      type: "renders",
      payload: {
        form: data_result4.link,
        sdklink: data_result4.mainlink,
        purewebProjectId: data_result4.purewebProjectId,
        purewebModelId: data_result4.purewebModelId,
        purewebProjectIdGlobal: data_result4.purewebProjectIdGlobal,
        purewebModelIdGlobal: data_result4.purewebModelIdGlobal,
        purewebProjectIdDev: data_result4.purewebProjectIdDev,
        purewebModelIdDev: data_result4.purewebModelIdDev,
        publicDownloadUrl: data_result4.publicDownloadUrl,
      },
    });
  }
  if (data_result6) {
    dispatch({
      type: "downloads",
      payload: {
        youtubeLink: data_result6.youtubeLink,
        notes: data_result6.notes,
      },
    });
  }
  return true;
};
