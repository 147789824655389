import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { makeLinksClickable } from "./dashboard";

export function Downloads({ open }) {
  const selector = useSelector((state) => state.adminReducer);
  const notes = selector.downloadSection.notes;

  return (
    <>
      <div
        className={`${open && "toggling-fun"} container`}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="row justify-content-center bannerrender mt-4 mb-2">
          <Typography variant="h4" sx={{ fontWeight: 800 }}>
            Watch this first
          </Typography>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="container my--5 text-center square mr-md-5 mr-sm-0 pr-lg-3 pl-lg-3 pl-0 pr-0">
            <iframe
              // width="80%"
              // height="400"
              title="uniqueID"
              className="shadow-lg youtube-video"
              src={`https://www.youtube.com/embed/${
                selector.downloadSection.youtubeLink === ""
                  ? "d2cOXy4Qekg"
                  : selector.downloadSection.youtubeLink
              }`}
            />
          </div>
        </div>

        <Button
          onClick={() => {
            const url = selector?.renderSection?.publicDownloadUrl;
            if (url) {
              window.open(url, "_blank");
            }
          }}
          variant="contained"
          sx={{
            fontWeight: "700",
            "&:hover": {
              backgroundColor: "white",
              color: "#1976d2",
            },
            width: "fit-content",
            margin: "10px auto",
          }}
        >
          DOWNLOAD APPLICATION FOR WINDOWS
        </Button>

        <div className="row justify-content-center bannerrender mt-4 mb-2">
          <Typography
            variant="h4"
            className="text-center"
            sx={{ fontWeight: 800 }}
          >
            Note
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            backgroundColor: "white",
            marginBottom: 40,
          }}
        >
          <div
            className="container"
            style={{
              padding: 10,
              borderRadius: 10,
              display: "flex",
              justifyContent: "space-between",
              margin: "0 20px",
            }}
          >
            <div style={{ flexDirection: "column" }}>
              <div
                className="dashboard--news-description"
                dangerouslySetInnerHTML={{
                  __html: makeLinksClickable(notes),
                }}
                style={{ fontWeight: 400 }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
