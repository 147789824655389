import React, { useState } from "react";
import { downloadspageapi } from "./hooks/apis";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import ReactQuill from "react-quill";

const Downloads = () => {
  const selector = useSelector((state) => state.adminReducer);
  const [data, setData] = useState({
    youtube: "",
    notes: selector.downloadSection.notes,
  });

  const response = useSelector((state) => state.adminReducer.download_message);
  const [youtubelinkwarn, setYoutubelinkwarn] = useState(false);
  const dispatch = useDispatch();

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let youtubelinks, notes;
    if (data.youtube) {
      youtubelinks = youtube_parser(data.youtube);
      if (!youtubelinks) {
        setYoutubelinkwarn(true);
        return;
      }
    }

    if (data.notes) {
      notes = data.notes;
    }

    downloadspageapi(data, youtubelinks, notes, dispatch);
    setData({
      youtube: "",
    });
  };

  return (
    <>
      {response === "show" && (
        <div
          className="alert alert-success mt-2 text-center font-weight-bold"
          role="alert"
        >
          Changes are successfully applied
        </div>
      )}
      {response === "no_show" && (
        <div className="alert alert-danger" role="alert">
          Some Error occured
        </div>
      )}
      <section className="row my-3 justify-content-end mx-2">
        <button
          type="submit"
          style={{ backgroundColor: "#1565c0", color: "white" }}
          className="my-2 py-2 px-3 rounded font-weight-bold oncea-button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </section>

      <section
        className="w-100 d-flex justify-content-center bannerdec2"
        style={{
          borderBottom: "0.2rem solid rgb(25, 118, 210)",
          borderRadius: "8px",
        }}
      >
        <header
          className="col-lg col-12 text-left h5 bannerfont"
          style={{ fontWeight: 600 }}
        >
          Youtube Video
        </header>
        <div className="w-50">
          <form className="d-flex flex-column justify-content-left align-items-left col shadow card my-2">
            <label htmlFor="video1" className="text-left mt-2 ">
              Youtube Video Link
            </label>
            <input
              type="text"
              name="youtubes"
              id="video1"
              className={`mb-3 ${youtubelinkwarn && "border-danger"}`}
              value={data.youtube}
              onChange={(e) => {
                setYoutubelinkwarn(false);
                setData(() => ({ ...data, youtube: e.target.value }));
              }}
            />
          </form>
          <Box>
            <div
              style={{
                backgroundColor: "white",
                marginBottom: 20,
              }}
            >
              <ReactQuill
                theme="snow"
                value={data.notes}
                onChange={(notes) => {
                  setData((current) => ({
                    ...current,
                    notes,
                  }));
                }}
                placeholder="Enter notes"
              />
            </div>
          </Box>
        </div>
      </section>
    </>
  );
};
export default Downloads;
